<template>
  <div>
    <van-nav-bar
        title="方安物业电费充值列表"
        :left-arrow="isarrow"
        @click-left="onClickLeft"

    />

    <van-card v-for='item in list'
        :key="item.Type"
        :price="item.Price"


        :thumb="img"
    >

      <template #title>
        <van-tag plain type="danger" v-if="item.fdElectricRecharge.PaymentMethod=='ManualRecharge'">人工充值</van-tag>
        <van-tag plain type="danger" v-if="item.fdElectricRecharge.PaymentMethod=='wxPay'">微信充值</van-tag>
      </template>
      <template #desc>
        <div>交易单号：{{item.fdElectricRecharge.TransctionId}}</div>
      </template>
      <template #footer>
        <div>缴费日期：{{item.Addtime}}</div>
      </template>

    </van-card>
    <van-pagination v-model="currentPage" :page-count="total" mode="simple"   @change="changePage" />
  </div>
</template>

<script>
import {Button, Cell, CellGroup, List, NavBar, ContactList, Tag, Form, Field, Dialog,Card,Pagination} from 'vant';
import {queryDoorElectricNoList} from "@/api/wxmppay";
import img from '@/assets/gymimg.png'

export default {
  name: "Orderlist",
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]:  CellGroup,
    [List.name]:List,
    [NavBar.name]:NavBar,
    [ContactList.name]:ContactList,
    [Tag.name]:Tag,
    [Form.name]: Form,
    [Field.name]:Field,
    [Dialog.name]: Dialog,
    [Card.name]: Card,
    [Pagination.name]: Pagination


  },
  data(){
    return {
      isarrow: true,
      list:[],
      query:{
        doorno:"",
        pageIndex: 1
      },
      img:img,
      currentPage:"1",
      total:""
    }
  },
  created() {
    this.query.doorno=this.$route.query.doorno
    //根据房间号获取电费记录数据
    queryDoorElectricNoList(this.query).then(res=>{
      this.list=res.List;
      this.total=res.total
    })
  },
  methods:{
    onClickLeft(){
      this.$router.back(-1)
    },
    changePage(res){
      console.log(res)
      this.query.doorno=this.$route.query.doorno
      this.query.pageIndex=res
      //根据房间号获取电费记录数据
      queryDoorElectricNoList(this.query).then(res=>{
        this.list=res.List;
        this.total=res.total

      })
    }
  }
}
</script>

<style scoped>

</style>